<template>
    <div class="london bg-dark">
        <div class="all-area">
            <HeaderSection/>
            <AboutMe/>
            <ContactMe/>
            <FooterSection/>
        </div>
    </div>
</template>

<script>
import HeaderSection from '@/components/Header/HeaderSection.vue'
import AboutMe from './AboutSection.vue'
import FooterSection from '@/components/Footer/FooterSection.vue'

export default {
    name: 'HomePage',
    components: {
        HeaderSection,
        AboutMe,
        FooterSection
    }
}
</script>

<style>

</style>