<template>
    <section id="home" class="section welcome-area bg-inherit d-flex align-items-center h-100vh">
        <div class="container">
            <div class="row align-items-center">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-7 col-lg-6">
                    <div class="welcome-intro">
                        <h1 class="text-warning">Shimmermare</h1>
                        <h3 class="text-white">Denis Pavlov • Software Engineer</h3>
                        <h3 class="text-warning text-monospace mt-3 mb-1">&gt;whoami</h3>
                        <p class="text-white my-2">Software engineer, nerd, brony, furry <span class="text-muted">UwU</span></p>
                        <p class="text-white my-2">Started programming with Minecraft mods, later expanding into making games with Unity and finally into full-time backend dev job.</p>
                        <p class="text-white my-2">Primarily, I work on enterprise backends written in Java/Kotlin, with most of my experience in FinTech and marketplace development.</p>
                        <p class="text-white my-2">As a hobby, I'm working on <a href="https://megaspell.net" class="text-info">Megaspell</a> - a post-apocalyptic top-down action RPG on Unity.</p>
                    </div>
                    <div class="welcome-intro">
                        <h3 class="text-warning text-monospace mt-3 mb-1">&gt;links</h3>
                      <div>
                        <img src="assets/img/icons/email_icon.svg" width="24" height="24" alt="E-mail" class="m-2">
                        <a class="text-info" href="mailto:shimmermare@gmail.com">shimmermare@gmail.com</a>
                      </div>
                      <div>
                        <img src="assets/img/icons/Telegram-Logo.svg" width="24" height="24" alt="Telegram" class="m-2">
                        <a class="text-info" href="https://t.me/shimmermare" target="_blank">t.me/shimmermare</a>
                      </div>
                      <div>
                        <img src="assets/img/icons/discord-mark-blue.svg" width="24" height="24" alt="Discord" class="m-2">
                        <span class="text-warning">shimmmermare</span>
                      </div>
                      <div>
                        <img src="assets/img/icons/github-mark-white.png" width="24" height="24" alt="LinkedIn" class="m-2">
                        <a class="text-info" href="https://github.com/shimmermare/" target="_blank">github.com/shimmermare</a>
                      </div>
                      <div>
                        <img src="assets/img/icons/LI-In-Bug.png" width="24" height="24" alt="LinkedIn" class="m-2">
                        <a class="text-info" href="https://www.linkedin.com/in/shimmermare/" target="_blank">linkedin.com/in/shimmermare</a>
                      </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 mx-auto pt-4 pt-md-0">
                    <!-- Welcome Thumb -->
                    <div class="welcome-thumb">
                        <img src="assets/img/sunset.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutMe'
}
</script>

<style>

</style>